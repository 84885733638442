import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import loadable from '@loadable/component';

//import App from './App';
// const App = loadable(() => import("./App"));
const ToolPage = loadable(() => import("./components_new/Tool.js"));
// const FeaturePage = loadable(() => import("./components_new/FeatureCardPage.js"));
const ReportPage = loadable(() => import("./components_new/Report"));
const GraphPage = loadable(() => import("./components_new/Graph"));

ReactDOM.render(
  <BrowserRouter>
  <Routes>
    <Route path="" element={<ToolPage/>} />
    {/* <Route path="error" element={<e/>} />
    <Route path="smartmuv-features" element={<FeaturePage/>} />
    <Route path="/tool" element={<ToolPage/>} /> */}
    <Route path="report" element={<ReportPage/>}/>
    <Route path="graph-generator" element={<GraphPage/>}/> 
  </Routes>
</BrowserRouter>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
